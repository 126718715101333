<template>
	<SystemEmailsLayout>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<SystemEmailsTab
						:system-emails="systemEmails"
						:email-types="emailTypes"
						:email-type-items="emailTypeFilterItems"
						@load-system-emails="loadSystemEmails"
					/>
				</template>
				<template #1>
					<SystemEmailsTranslationsTab
						:system-emails="filteredSystemEmails"
						:previously-selected-language="previouslySelectedLanguage"
						:email-types="emailTypes"
						:email-type-items="filteredEmailTypes"
						@load-system-emails="loadSystemEmails"
					/>
				</template>
			</TabItems>
		</template>
	</SystemEmailsLayout>
</template>

<script>
import Tabs from '../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'
import SystemEmailsTab from './system-emails-tab.vue'
import SystemEmailsLayout from './system-emails-layout.vue'
import SystemEmailsTranslationsTab from './system-emails-translations-tab.vue'
import { getSystemEmails } from '../../../../../shared/utils/api/system-emails.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
export default {
	components: { SystemEmailsLayout, SystemEmailsTab, SystemEmailsTranslationsTab, Tabs, TabItems },
	props: {
		previouslySelectedLanguage: Number,
		accessedViaTranslation: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			tabs: [{ title: useLabels('System Emails'), slot: '0' }, { title: useLabels('System Email Translations'), slot: '1' }],
			tab: '0',
			systemEmails: [],
			emailTypes: []
		}
	},
	computed: {
		emailTypeFilterItems () {
			return [
				{ text: 'View All', value: null },
				...this.emailTypes.map(emailType => ({
					...emailType,
					text: emailType.emailTypeDescription,
					value: emailType.emailTypeId
				}))
			]
		},
		filteredEmailTypes () {
			return [
				{ text: 'View All', value: null },
				...this.emailTypes.filter(emailType => emailType.isTranslatable === true).map(emailType => ({
					...emailType,
					text: emailType.emailTypeDescription,
					value: emailType.emailTypeId
				}))
			]
		},
		filteredSystemEmails () {
			return this.systemEmails.filter(email => {
				return this.filteredEmailTypes.find(type => type.emailTypeId === email.emailTypeId)
			})
		}
	},
	created () {
		this.loadSystemEmails()
		if (this.accessedViaTranslation) {
			this.tab = '1'
		}
	},
	methods: {
		async loadSystemEmails () {
			const { data: { systemEmails, emailTypes } } = await getSystemEmails()
			this.systemEmails = systemEmails.map(systemEmail => ({
				...systemEmail
			}))
			this.emailTypes = emailTypes
		}
	}
}
</script>
