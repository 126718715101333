<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="systemEmailSearchQuery"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			search-query-label="System Email Name"
			@persistSearchQuery="changeSystemEmailSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					:value="systemEmailTypeFilter"
					label="Email Type"
					:items="emailTypeItems"
					custom-sort
					@input="changeSystemEmailTypeFilter($event)"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="!readOnlyPermissions"
					@click="onCreateClick"
				>
					<v-icon
						left
						dark
					>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				System Emails
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredSystemEmails"
					sort-by="systemEmailId"
					sort-desc
					@click:row="rowClick"
				>
					<template #item.isCSPortalConfirmationEmail="{ item }">
						{{ item.isCSPortalConfirmationEmail ? 'Yes' : 'No' }}
					</template>
					<template #item.isPublicPortalConfirmationEmail="{ item }">
						{{ item.isPublicPortalConfirmationEmail ? 'Yes' : 'No' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="!readOnlyPermissions"
							tooltip-text="Edit System Email"
							@click="onEditSystemEmail(item)"
						>
							{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View System Email"
							@click="onEditSystemEmail(item)"
						>
							{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
						</IconButton>
						<IconButton
							v-if="!readOnlyPermissions"
							tooltip-text="Send Test Email"
							@click.stop.prevent="openSendTestEmailModal(item)"
						>
							mdi-email
						</IconButton>
						<IconButton
							v-if="!readOnlyPermissions"
							tooltip-text="Delete System Email"

							@click.stop.prevent="onRemoveSystemEmail(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="systemEmailToBeRemoved && !readOnlyPermissions"
			:entity-name="systemEmailToBeRemoved.systemEmailName"
			entity-type="System Email"
			@close="systemEmailToBeRemoved = null"
			@delete="deleteSystemEmail"
		/>
		<SendTestEmailModal
			v-if="systemEmailTestSendModal"
			:system-email-item="systemEmailItem"
			@close="systemEmailTestSendModal = null"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { MANAGE_SYSTEM_EMAIL } from '../../../router/route-names.js'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import SendTestEmailModal from './confirm-email-test-send-modal.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { deleteSystemEmail } from '../../../../../shared/utils/api/system-emails.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { SYSTEM_EMAILS_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { changeSystemEmailSearchQuery, systemEmailSearchQuery, changeSystemEmailTypeFilter, systemEmailTypeFilter } from '../../../../../shared/state/system-emails.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'

export default {
	components: { PrimaryActionButton, SectionCard, DataTable, ConfirmDeleteModal, FilterBar, IconButton, Dropdown, SendTestEmailModal },
	props: {
		systemEmails: {
			type: Array,
			default: () => []
		},
		emailTypes: {
			type: Array,
			default: () => []
		},
		emailTypeItems: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeSystemEmailSearchQuery,
			systemEmailSearchQuery,
			changeSystemEmailTypeFilter,
			systemEmailTypeFilter
		}
	},
	data () {
		return {
			searchQuery: null,
			selectedBrandId: null,
			systemEmailToBeRemoved: null,
			tableHeaders: [
				{ value: 'systemEmailId', text: 'ID', width: '10%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'systemEmailName', text: 'System Email Name', width: '15%' },
				{ value: 'emailTypeName', text: 'Email Type', width: '15%' },
				{ value: 'isPublicPortalConfirmationEmail', text: 'Public Portal Confirmation Email?', width: '10%' },
				{ value: 'createdByDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			],
			systemEmailTestSendModal: false,
			systemEmailItem: false
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		readOnlyPermissions () {
			return !this.checkPermission(SYSTEM_EMAILS_MODULE_FULL_PERMISSIONS)
		},
		systemEmailsType () {
			return this.systemEmails
				.map(email => {
					const emailType = this.emailTypes?.find(({ emailTypeId }) => emailTypeId === email.emailTypeId)
					return {
						...email,
						emailTypeName: emailType?.emailTypeDescription ?? ''
					}
				})
		},
		filteredSystemEmails () {
			return this.systemEmailsType.filter(({ systemEmailName, brandId, emailTypeId }) => {
				let check = true
				if (systemEmailSearchQuery.value) check = systemEmailName.toLowerCase().includes(systemEmailSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandId
				if (systemEmailTypeFilter.value !== null) check = check && systemEmailTypeFilter.value === emailTypeId
				return check
			})
		}
	},
	methods: {
		async deleteSystemEmail () {
			await deleteSystemEmail(this.systemEmailToBeRemoved.systemEmailId)
			this.systemEmailToBeRemoved = null
			showSnackbar('This system email has been removed')
			this.$emit('load-system-emails')
		},
		onCreateClick () {
			this.$router.push({
				name: MANAGE_SYSTEM_EMAIL,
				params: {
					emailTypes: this.emailTypes
				}
			})
		},
		onEditSystemEmail (systemEmailToEdit) {
			this.$router.push({
				name: MANAGE_SYSTEM_EMAIL,
				params: {
					systemEmailToEdit: systemEmailToEdit,
					emailTypes: this.emailTypes
				}
			})
		},
		onRemoveSystemEmail (item) {
			this.systemEmailToBeRemoved = item
		},
		openSendTestEmailModal (systemEmailItem) {
			this.systemEmailItem = systemEmailItem
			this.systemEmailTestSendModal = true
		},
		rowClick (row) {
			if (this.readOnlyPermissions) {
				row.readOnly = true
			}
			this.systemEmail = row
			this.onEditSystemEmail(row)
		},
		clearFilters () {
			changeSystemEmailTypeFilter(null)
		}
	}
}
</script>
