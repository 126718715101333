<template>
	<validation-observer
		#default="{ handleSubmit, reset }"
	>
		<Modal
			width="700px"
		>
			<template #modal-title>
				Send Test Email for {{ systemEmailItem.systemEmailName }}
			</template>
			<template #modal-content>
				<div class="d-flex flex-row">
					Enter the email address below that you
					<br>
					would like to send a test system email to
					<br>
				</div>
				<br>
				<div class="d-flex flex-row">
					<TextField
						v-model="receiverEmailAddress"
						class="cassie-input-width-xl"
						label="Email Address *"
						:rules="{ required: true, email: true, max:120 }"
					/>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close(reset)">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton @click="handleSubmit(submit)">
					Send
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import Modal from '../../../../../shared/components/modal.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import { sendTestSystemEmail } from '../../../../../shared/utils/api/system-emails.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: { PrimaryActionButton, SecondaryActionButton, Modal, TextField },
	props: {
		systemEmailItem: Object
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			receiverEmailAddress: null
		}
	},
	methods: {
		close () {
			this.$emit('close', true)
		},
		async submit (reset) {
			const sendTestEmailRequest = {
				sendEmailID: this.systemEmailItem.systemEmailId,
				emailAddress: this.receiverEmailAddress,
				languageID: this.systemEmailItem?.languageId
			}
			await sendTestSystemEmail(sendTestEmailRequest)
			showSnackbar('Test Email has been sent')
			this.close()
		}
	}
}
</script>
